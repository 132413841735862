<div>
  <mat-form-field appearance="fill">
    <mat-label>Select Hashing Algo.</mat-label>
    <mat-select [(ngModel)]="methode">
      <mat-option value="md5">MD5 (cryptoJS)</mat-option>
      <mat-option value="sha1">SHA1 (WebCrypto Api)</mat-option>
      <mat-option value="sha3">SHA3 (cryptoJS)</mat-option>
      <mat-option value="sha256">SHA256 (WebCrypto Api)</mat-option>
      <mat-option value="sha384">SHA384 (WebCrypto Api)</mat-option>
      <mat-option value="sha512">SHA512 (WebCrypto Api)</mat-option>
      <mat-option value="ripemd160">RIPEMD160 (cryptoJS)</mat-option>
    </mat-select>
  </mat-form-field>
  <br/>
  <mat-form-field style="width: 50pc">
    <mat-label>Text</mat-label>
    <textarea [(ngModel)]="data" matInput placeholder="Enter a text to hash"></textarea>
  </mat-form-field>
  <br/>
  <button (click)="hash()" mat-flat-button color="primary">Hash</button>
  <br/>
  <br/>
  <label>{{result}}</label>
</div>
