import { Component, OnInit } from '@angular/core';
import {UserDataCryptoService} from '../user-data-crypto.service';
import {ToolsService} from '../tools.service';

@Component({
  selector: 'app-password-derived-aeskey',
  templateUrl: './password-derived-aes-key.component.html',
  styleUrls: ['./password-derived-aes-key.component.css']
})
export class PasswordDerivedAesKeyComponent implements OnInit {
  algorithm: string;
  password: string;
  resultKey: string;
  data: string;
  keyIsPresent = false;
  private key: CryptoKey;
  vi: string;
  resultData: string;

  constructor(
    private userDataCryptoService: UserDataCryptoService,
    private tools: ToolsService,
  ) { }

  ngOnInit(): void {
  }

  deriveKey(): void {
    console.log(this.password);
    this.userDataCryptoService.deriveKey(this.password, this.algorithm).then((key) => {
      console.log(key);
      this.key = key;
      this.keyIsPresent = true;
    }).catch(reason => {
      alert('Failed to derive Key');
      console.error(reason);
    });
    return;
  }

  decrypt(): void {
    this.userDataCryptoService.decryptDecodeAsString(this.data, this.vi, this.key, this.algorithm).then(value => {
      this.resultData = value;
    }).catch(reason => {
      alert('Failed to decrypt');
      console.error(reason);
    });
    return;
  }

  encrypt(): void {
    this.userDataCryptoService.encryptEncodeBase64(this.data, this.vi, this.key, this.algorithm).then(value => {
      this.resultData = value;
    }).catch(reason => {
      alert('Failed to encrypt');
      console.error(reason);
    });
    return;
  }

  exportKey(): void {
    this.userDataCryptoService.exportKet(this.key).then(value => {
      const data = this.tools.bufferToBase64((value as ArrayBuffer));
      console.log(data);
      this.resultKey = data;
    }).catch(e => {
      console.log(e);
    });
  }
}
