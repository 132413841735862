import { Injectable } from '@angular/core';
import {ToolsService} from './tools.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataCryptoService {

  constructor(
    private tools: ToolsService
  ) { }

  private enc = new TextEncoder();
  private dec = new TextDecoder();

  public ivLen = 16;

  private getPasswordKey(password: string): Promise<CryptoKey> {
    return window.crypto.subtle.importKey(
      'raw',
      this.enc.encode(password),
      'PBKDF2',
      false,
      ['deriveKey']
    );
  }

  private  deriveKeyInternal(passwordKey, salt, keyUsage, keyAlgorithm): Promise<CryptoKey> {
    return window.crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt,
        iterations: 250000,
        hash: 'SHA-256',
      },
      passwordKey,
      { name: keyAlgorithm, length: 256 },
      true,
      keyUsage
    );
  }

  public async deriveKey(password: string, keyAlgorithm: string): Promise<CryptoKey> {
    const salt = Uint8Array.from([1, 2, 3, 4, 5, 6, 4, 7, 5, 6]);
    const passwordKey = this.getPasswordKey(password).then(key => {
      return this.deriveKeyInternal(key, salt, ['encrypt', 'decrypt'], keyAlgorithm).then( ase => {
          return ase;
        }
      );
    });
    return passwordKey;
  }

  public encrypt(data: string, iv: string, aesKey: CryptoKey, keyAlgorithm: string): Promise<ArrayBuffer>{
    return window.crypto.subtle.encrypt(
      {
        name: keyAlgorithm,
        iv: this.enc.encode(iv),
        tagLength: 128
      },
      aesKey,
      this.enc.encode(data)
    );
  }

  public encryptEncodeBase64(data: string, iv: string, aesKey: CryptoKey, keyAlgorithm: string): Promise<string> {
    const base64 = this.encrypt(data, iv, aesKey, keyAlgorithm).then((e) => {
      return this.tools.bufferToBase64(new  Uint8Array(e));
    });
    return base64;
  }

  public decrypt(data: ArrayBuffer, iv: string, aesKey: CryptoKey, keyAlgorithm: string): Promise<ArrayBuffer>{
    return window.crypto.subtle.decrypt(
      {
        name: keyAlgorithm,
        iv: this.enc.encode(iv),
        tagLength: 128
      },
      aesKey,
      data
    );
  }

  public decryptDecodeAsString(data: string, iv: string, aesKey: CryptoKey, keyAlgorithm: string): Promise<string> {
    const decoded = this.decrypt(this.tools.base64ToBuffer(data), iv, aesKey, keyAlgorithm).then((encrypted) => {
      return this.dec.decode(encrypted);
    });
    return decoded;
  }

  public exportKet(key: CryptoKey): Promise<ArrayBuffer | JsonWebKey> {
    return crypto.subtle.exportKey('raw', key);
  }

}
