<p>
  <mat-toolbar color="primary">
    <button mat-icon-button aria-label="Example icon-button with menu icon">
      <mat-icon>menu</mat-icon>
    </button>
    <span>{{title}}</span>
    <div>
      <a mat-button [routerLink]="'/hashing'"> Hashing </a>
      <a disabled mat-button [routerLink]="'/pgp'"> PGP </a>
      <a mat-button [routerLink]="'/sym-crypto'"> Symmetric crypto </a>
      <a mat-button [routerLink]="'/asym-crypto'"> Asymmetric crypto </a>
      <a mat-button href="https://gitlab.com/linalinn/opsec.jetzt"> SourceCode </a>
    </div>
  </mat-toolbar>
</p>




<router-outlet></router-outlet>
