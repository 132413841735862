import { Component, OnInit } from '@angular/core';
import {AsymmetricKeyService} from '../asymmetric-key.service';

@Component({
  selector: 'app-asymetric-crypto',
  templateUrl: './asymetric-crypto.component.html',
  styleUrls: ['./asymetric-crypto.component.css']
})
export class AsymetricCryptoComponent implements OnInit {
  private cryptoKeyPair: CryptoKeyPair;
  private = '';
  public = '';
  data = '';

  constructor(
    private asymmetricKeyService: AsymmetricKeyService,
  ) { }

  ngOnInit(): void {}


  genKey(): void {
    this.asymmetricKeyService.genrateRSAKey(4096).then((keypair) => {
      this.cryptoKeyPair = keypair;
      this.asymmetricKeyService.extractJWK(keypair.publicKey).then( key => {
        console.log(key);
        this.public = JSON.stringify(key);
      });
      this.asymmetricKeyService.extractJWK(keypair.privateKey).then( key => {
        console.log(key);
        this.private = JSON.stringify(key);
      });
    });
  }

}
