
<form>
  <button mat-flat-button color="primary" (click)="genKey()">Gen 4096bit RSA keypair</button>

  <p>Public Key (JSON Web Key)<p>
  <textarea [value]="public"></textarea>

  <p>Private Key (JSON Web Key)<p>
  <textarea [value]="private"></textarea>

  <p>Enter Data to be de/encrypted<p>
  <textarea [value]="data"></textarea>
  <br/>
  <button disabled mat-flat-button color="primary" (click)="genKey()">Encrypt</button>
  <button disabled mat-flat-button color="primary" (click)="genKey()">Decrypt</button>
</form>
