import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AsymmetricKeyService {

  constructor() { }


  public genrateRSAKey(length: number): Promise<CryptoKeyPair>{
    return window.crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: length,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-256'
      },
      true,
      ['encrypt', 'decrypt']
    );
  }

  public extractJWK(key: CryptoKey): Promise<JsonWebKey>{
    return window.crypto.subtle.exportKey('jwk', key);
  }
}
