import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor() { }

  public bufferToBase64( buffer ): string {
    let binary = '';
    const bytes = new Uint8Array( buffer );
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  public base64ToBuffer(base64): Uint8Array {
    const binStr = atob(base64);
    const buf = new Uint8Array(binStr.length);
    Array.prototype.forEach.call(binStr,  (ch, i) => {
      buf[i] = ch.charCodeAt(0);
    });
    return buf;
  }

}
