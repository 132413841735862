import {Injectable} from '@angular/core';
import * as cryptojs from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class HashService {

  constructor() {
  }

  public getMD5(str: string): string {
    return cryptojs.MD5(str).toString(cryptojs.enc.Hex);
  }

  public getSHA1(str: string): Promise<string | void> {
    return this.hashWithWebCrypto(str, 'SHA-1');
  }

  public getSHA3(str: string): string {
    return cryptojs.SHA3(str).toString(cryptojs.enc.Hex);
  }

  public getSHA256(str: string): Promise<string | void> {
    return this.hashWithWebCrypto(str, 'SHA-256');
  }

  public getSHA384(str: string): Promise<string | void> {
    return this.hashWithWebCrypto(str, 'SHA-384');
  }

  public getSHA512(str: string): Promise<string | void> {
    return this.hashWithWebCrypto(str, 'SHA-512');
  }

  public getRIPEMD160(str: string): string {
    return cryptojs.RIPEMD160(str).toString(cryptojs.enc.Hex);
  }

  private hashWithWebCrypto(str: string, algorithm: string): Promise<string | void> {
    return crypto.subtle.digest(algorithm, new TextEncoder().encode(str)).then((buf) => {
      return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
      }
    );
  }
}
