<div>
  <mat-form-field appearance="fill">
    <mat-label>Select derived Key Algo.</mat-label>
    <!--  AES-CTR, AES-CBC, AES-GCM, or AES-KW -->
    <mat-select [(ngModel)]="this.algorithm" select="AES-GCM">
      <mat-option value="AES-GCM">AES GCM</mat-option>
      <mat-option disabled value="AES-CTR">AES CTR</mat-option>
      <mat-option disabled value="AES-CBC">AES CBC</mat-option>
      <mat-option disabled value="AES-KW">AES KW</mat-option>
      <mat-option disabled value="AES-KW">DES (cryptoJS)</mat-option>
      <mat-option disabled value="AES-KW">Triple DES (cryptoJS)</mat-option>
      <mat-option disabled value="AES-KW">Rabbit (cryptoJS)</mat-option>
      <mat-option disabled value="AES-KW">RC4 (cryptoJS)</mat-option>
      <mat-option disabled value="AES-KW">AES KW</mat-option>
    </mat-select>
  </mat-form-field>
  <br/>
  <mat-form-field style="width: 50pc">
    <mat-label>Password</mat-label>
    <input [(ngModel)]="this.password" matInput placeholder="Enter Password that should be used to derive the key"/>
  </mat-form-field>
  <br/>
  <br/>
  <button (click)="deriveKey()" mat-flat-button color="primary">Derive Key</button>
  <button [disabled]="!this.keyIsPresent" (click)="exportKey()" mat-flat-button color="primary">Export Key</button>
  <br/>
  <br/>
  <label *ngIf="resultKey" >Key (raw Base64 encoded):{{resultKey}}</label>
  <br/>
  <br/>
  <mat-form-field style="width: 50pc">
    <mat-label>vi</mat-label>
    <input [(ngModel)]="this.vi" matInput placeholder="Enter vi"/>
  </mat-form-field>
  <br/>
  <mat-form-field style="width: 50pc">
    <mat-label>Text to encrypt/decrypt</mat-label>
    <textarea [(ngModel)]="this.data" matInput placeholder="Enter a text to encrypt/decrypt"></textarea>
  </mat-form-field>
  <br/>
  <button [disabled]="!this.keyIsPresent" (click)="decrypt()" mat-flat-button color="primary">Decrypt</button>
  <button [disabled]="!this.keyIsPresent" (click)="encrypt()" mat-flat-button color="primary">Encrypt</button>
  <br/><br/><label>Encrypted/Decrypted:<br/>{{resultData}}</label>
</div>
