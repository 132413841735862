import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashComponent } from './hash/hash.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {MatNativeDateModule} from '@angular/material/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {DemoMaterialModule} from './material-module';
import {MatButton} from "@angular/material/button";
import { PasswordDerivedAesKeyComponent } from './password-derived-aeskey/password-derived-aes-key.component';
import { AsymetricCryptoComponent } from './asymetric-crypto/asymetric-crypto.component';

// @ts-ignore

@NgModule({
  declarations: [
    AppComponent,
    HashComponent,
    PasswordDerivedAesKeyComponent,
    AsymetricCryptoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatNativeDateModule,
    DemoMaterialModule,
    ReactiveFormsModule,
  ],
  exports: [
    MatFormField,
    MatButton,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
