import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HashComponent} from './hash/hash.component';
import {PasswordDerivedAesKeyComponent} from './password-derived-aeskey/password-derived-aes-key.component';
import {AsymetricCryptoComponent} from './asymetric-crypto/asymetric-crypto.component';

const routes: Routes = [
  { path: 'hashing', component: HashComponent},
  { path: 'sym-crypto', component: PasswordDerivedAesKeyComponent},
  { path: 'asym-crypto', component: AsymetricCryptoComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
