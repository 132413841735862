import {Component, OnInit} from '@angular/core';
import {HashService} from "../hash.service";

@Component({
  selector: 'app-hash',
  templateUrl: './hash.component.html',
  styleUrls: ['./hash.component.css']
})
export class HashComponent implements OnInit {
  data: string;
  methode: string;
  public result = '';

  constructor(
    private hashService: HashService,
  ) {
  }

  ngOnInit(): void {
  }

  public hash(): void {
    switch (this.methode) {
      case 'md5': {
        this.result = this.hashService.getMD5(this.data);;
        return;
      }
      case 'sha1': {
        this.hashService.getSHA1(this.data).then((r) => {
          if (typeof r === 'string'){
            this.result = r;
          }
        });
        return;
      }
      case 'sha3': {
        this.result = this.hashService.getSHA3(this.data);
        return;
      }
      case 'sha256': {
        this.hashService.getSHA256(this.data).then((r) => {
          if (typeof r === 'string'){
            this.result = r;
          }
        });
        return;
      }
      case 'sha384': {
        this.hashService.getSHA384(this.data).then((r) => {
          if (typeof r === 'string'){
            this.result = r;
          }
        });
        return;
      }
      case 'sha512': {
        this.hashService.getSHA512(this.data).then((r) => {
          if (typeof r === 'string'){
            this.result = r;
          }
        });
        return;
      }
      case 'ripemd160': {
        this.result = this.hashService.getRIPEMD160(this.data);
        return;
      }
    }
    return;
  }

}
